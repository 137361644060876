/* eslint-disable react/destructuring-assignment,react/prop-types */
import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutFunc = ({ firebase }) => {
  firebase && firebase.doSignOut();
  return (
    <button type="button" onClick={firebase ? firebase.doSignOut : () => {}}>
      Sign Out
    </button>
  );
};

export default withFirebase(SignOutFunc);
