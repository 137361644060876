/* eslint-disable no-undef, react/prop-types */
// eslint-disable-next-line max-classes-per-file
import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import PageTransition from '../components/PageTransition/index';
import { AuthUserContext } from '../components/Session';
import SignOutFunc from '../components/SignOutFunc';
import { Box, Flex } from '../style/basicStyle';

// eslint-disable-next-line react/prefer-stateless-function
class SignOutRender extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SignOutFunc />
      </>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
class Signout extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      if (window) {
        navigate('/');
      }
    }, 500);
  }

  render() {
    const { props } = this;
    const { location } = props;
    return (
      <PageTransition location={location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>Signing Out...</title>
        </Helmet>
        <AuthUserContext.Consumer>
          {(authUser) => {
            return authUser ? (
              <Box py={[5]} px={[2, 3, 5]}>
                <h1>Signing Out...</h1>
                <SignOutRender authUser={authUser} />
              </Box>
            ) : (
              <Box py={[5]} px={[2, 3, 5]}>
                <h1>Signing Out...</h1>
              </Box>
            );
          }}
        </AuthUserContext.Consumer>
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query signoutContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "signout" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;

export default Signout;
